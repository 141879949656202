import React from "react";
import { Link, graphql } from "gatsby";
import header_test from "../images/header_test.png";

import Layout from "../components/layout";
import BlogPreviewWrapper from "../components/BlogPreviewWrapper";
import BlogPreview2 from "../components/BlogPreview2";
import styled from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import SEO from "../components/seo";

const BodyContentEl = styled.article`
  max-width: 1200px;
  margin: auto;
  .blog-posts-nav-wrapper {
    padding-left: 1%;
    padding-right: 1%;

    .blog-posts-nav {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      margin-bottom: 16px;
      padding: 8px 0;
      margin-right: 0px;
      margin-left: 0;
      display: flex;
      flex-wrap: wrap;
      text-transform: uppercase;
      justify-content: center;
      font-size: 0.7rem;
      letter-spacing: 0.9px;

      .blog-posts-nav-item {
        margin-right: 40px;
        display: flex;
        span {
          margin-right: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .arrow-icon {
            line-height: 1px;
          }
        }
        .category-link {
          margin-right: 15px;
          cursor: pointer;
          text-decoration: none;
          color: inherit;

          &.category-link-active {
            font-weight: bold;
            color: #00bddf;
          }
        }
      }
    }
  }

  @media screen and (max-width: 731px) {
    .blog-posts-nav-wrapper .blog-posts-nav .blog-posts-nav-item {
      margin-right: 0px;

      span {
        &:lastchild {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 377px) {
    .blog-posts-nav-wrapper .blog-posts-nav .blog-posts-nav-item span {
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 352px) {
    .blog-posts-nav-wrapper
      .blog-posts-nav
      .blog-posts-nav-item
      .category-links {
      margin-right: 9px;
    }
  }
`;

class BlogPosts extends React.Component {
  // state = {
  //   perPage: 10,
  //   allPostsCount: null,
  //   currentPage: 1,
  //   blogPosts: []
  // }

  render() {
    const { data } = this.props;
    const searchString = this.props.location.search;

    // We check if searchString is empty, if it is, return an array and categoryParam will be
    // undefined. if not, split it across the = and the second item should be the categoryParam
    const [_unUsed, categoryParam] = searchString
      ? searchString.split("=")
      : [];

    const blogPosts = data.allContentfulBlogPost.edges;

    const categories = blogPosts.reduce((categories, blogpost) => {
      if (categories.includes(blogpost.node.category)) {
        return categories;
      } else {
        const updatedCategories = categories.concat([blogpost.node.category]);
        return updatedCategories;
      }
    }, []);

    return (
      <Layout
        heroText={categoryParam || "categories"}
        headerImage={header_test}
      >
        <SEO title="Blog posts" />
        <BodyContentEl>
          {/* <h1>{"We'll display a list of all blogposts here!"}</h1> */}
          <div className="blog-posts-nav-wrapper">
            <div className="blog-posts-nav">
              {/* <div className="blog-posts-nav-item">
                <span>
                  <FaChevronLeft className="arrow-icon" />
                  previous page
                </span>
                <span>page 1 of 3</span>
                <span>
                  next page
                  <FaChevronRight className="arrow-icon" />
                </span>
              </div> */}
              <div className="blog-posts-nav-item">
                <span>filter by:</span>
                <Link
                  to="/blogposts"
                  className={`${!categoryParam &&
                    "category-link-active"} category-link`}
                >
                  all
                </Link>
                {categories.map(category => (
                  <Link
                    key={category}
                    to={`/blogposts?category=${category}`}
                    className={`${categoryParam === category &&
                      "category-link-active"} category-link`}
                  >
                    {category}
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <BlogPreviewWrapper>
            {blogPosts
              .filter(blogpost => {
                if (categoryParam) {
                  return blogpost.node.category === categoryParam;
                } else {
                  return true;
                }
              })
              .map(blogpost => {
                return (
                  <BlogPreview2
                    key={blogpost.node.id}
                    blogpost={blogpost.node}
                  />
                );
              })}
          </BlogPreviewWrapper>
        </BodyContentEl>
      </Layout>
    );
  }
}

export default BlogPosts;

export const query = graphql`
  query BlogPostsPageQuery {
    allContentfulBlogPost(sort: { order: DESC, fields: [createdAt] }) {
      edges {
        node {
          id
          title
          slug
          image {
            file {
              url
            }
          }
          category
        }
      }
    }
  }
`;
